import React from 'react';
import Header from './components/header'; // Import the Header component
import Footer from './components/footer'; // Import the Footer component
// import PostGrid from './components/PostGrid'; // Import the PostGrid component

function App() {
  return (
    <div className="App min-h-screen bg-gray-900 text-white">
<Header></Header>
      <main className="container mx-auto px-4 py-8">
        
        <h1 className="text-3xl font-bold mb-8 text-center">Latest Posts</h1>
        {/* <PostGrid></PostGrid> */}
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
